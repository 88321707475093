<template>
	<div id="home">
		<v-head :title="title"></v-head>
		<swiper ref="mySwiper" :options="swiperOption" style="width: 100%; overflow-x: hidden;position: relative;">
			<swiper-slide  v-for="slide of [1, 2, 3]" :key="slide.id">
				<router-link to="/job/list" :class='{"header-content-container": true, "img": true, "show": slide==1}'>
					<img src="../../../assets/imgs/mobile/img_banneriphone@2x.webp" alt="">
				</router-link>
				<div :class='{"header-content-container": true, "show": slide==2}'>
					<div class="header-content">
						<h2 class="header-title">千位中醫師免費回復您的健康提問</h2>
						<p class="header-desc">100%回復，咨询團隊均有行醫資格認證</p>
					</div>
				</div>
				<div :class='{"header-content-container": true, "img": true, "show": slide==3}'>
					<img src="../../../assets/imgs/mobile/img_appbannertwo.webp" alt="">
				</div>
			</swiper-slide>
			<div class="swiper-pagination" slot="pagination"></div>
		</swiper>
		<ul class="tab-bar">
			<li class="tab-bar-item"><router-link to="/ask">咨詢醫師</router-link></li>
			<li class="tab-bar-item"><router-link to="/doctor">預約掛號</router-link></li>
			<li class="tab-bar-item"><router-link to="/job/list">職位空缺</router-link></li>
			<li class="tab-bar-item"><router-link to="/association/list">中醫學會</router-link></li>
		</ul>
		<div class="os-container">
			<router-link to="/os/doctor_signup">
				<img src="../../../assets/imgs/mobile/img_jiaon@2x.png" alt="">
			</router-link>
		</div>
		<div class="booking-count-box">
			<div class="count-item">
				<span class="value blue" v-text="(bookingCount && bookingCount.ordercount && bookingCount.ordercount.toLocaleString()) || ''"></span>
				<span class="name">已預約人次</span>
			</div>
			<div class="count-item">
				<span class="value red" v-text="(bookingCount && bookingCount.clientcount && bookingCount.clientcount.toLocaleString()) || ''"></span>
				<span class="name">顧客人數</span>
			</div>
			<div class="count-item">
				<span class="value" v-text="(bookingCount && bookingCount.validJobCount && bookingCount.validJobCount.toLocaleString()) || ''"></span>
				<span class="name">已發布職位空缺</span>
			</div>

		</div>
		<ul class="tab-items">
			<li :class="{'tab-item': true, 'current': tabItemIndex == 0}"><router-link to='/'>健康問答</router-link></li>
			<li :class="{'tab-item': true, 'current': tabItemIndex == 1}"><router-link to='/news/list'>健康資訊</router-link></li>
			<li v-if="userInfo" :class="{'tab-item': true, 'current': tabItemIndex == 3}"><router-link to='/my-ask'>我的問答</router-link></li>
			<li :class="{'tab-item': true, 'current': tabItemIndex == 2}"><router-link to='/about-os'>關於我們</router-link></li>
		</ul>
		<router-view></router-view>
	</div>
</template>

<script>
	import vHead from '@/layouts/MobileHead.vue'
	export default {
		components: {
			vHead
		},
		data() {
			return {
				title: null,
				tabItemIndex: 0,
				bookingCount: null,
				userInfo: this.$store.getters.userInfo,
				swiperOption: {
                    loop: true,
                    speed: 500,
                    delay: 3000,
                    autoplay: true,
                    autoplay: {
                        disableOnInteraction: false,
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    mousewheelControl: true,
                }
			}
		},
		mounted: function(){
			var path = this.$route.path
			this.tabItemIndex = 0
			if(path.indexOf('/news') != -1) {
				this.tabItemIndex = 1
				this.title = '健康資訊'
			} else if(path.indexOf('/about-os') != -1) {
				this.tabItemIndex = 2
				this.title = '關於我們'
			} else if(path.indexOf('/my-ask') != -1) {
				this.tabItemIndex = 3
				this.title = '我的問答'
			}
            this.getBookingCount()
		},
		methods: {
			getBookingCount: function() {
				this.$store.dispatch('getBookingCount', null)
				.then((data)=>{
					this.bookingCount = data
				})
			}
		},
		watch: {
			$route(to,from){
				var path = to.path
				this.tabItemIndex = 0
				if(path.indexOf('/news') != -1) {
					this.tabItemIndex = 1
					this.title = '健康資訊'
				} else if(path.indexOf('/about-os') != -1) {
					this.tabItemIndex = 2
					this.title = '關於我們'
				} else if(path.indexOf('/my-ask') != -1) {
					this.tabItemIndex = 3
					this.title = '我的問答'
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.swiper-pagination{
		bottom: 40px;
	}

	#home {
		padding-top: 83px;
	}

	#home .booking-count {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
		align-items: flex-start;
		margin: 25px 0px 21px 25px;
    }

	#home .booking-count .row-c {
		display: flex;
		flex-direction: row;
		background-color: rgba(255, 128, 0, 0.1);
		padding: 8px 87px 8px 13px;
		position: relative;
		align-items: center;
	}

	#home .booking-count .row-c > .hint {
        color: #221E1F;
        font-size: 26px;
        font-weight: 400;
		margin-right: 8px;
	}

	#home .booking-count .row-c > .value {
		font-size: 34px;
		font-weight: bold;
		color: #FF8000;
	}

	#home .booking-count .row-c::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 4px;
		background-color: #FF8000;
	}
	
	#home .booking-count .row-c:nth-child(2) {
		margin-top: 2px;
		padding-right: 61px;
		background-color: rgba(54, 196, 208, 0.1);
	}

	#home .booking-count .row-c:nth-child(2) > .value {
		color: #36C4D0;
	}

	#home .booking-count .row-c:nth-child(2)::before {
		background-color: #36C4D0;
	}
	
	#home > .booking-count-box {
		height: 148px;
		margin: 0px 24px 24px 24px;
		background-color: #fff;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-evenly;
	}

	#home > .booking-count-box .count-item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #717171;
		font-size: 24px;
		line-height: 1.0;
	}

	#home > .booking-count-box .count-item .value {
		font-weight: 900;
		font-size: 35px;
		color: #151515;
		margin-bottom: 15px;
	}

	#home > .booking-count-box .count-item .value.blue {
		color: #59C3D0;
	}

	#home > .booking-count-box .count-item .value.red {
		color: #F8882E;
	}

	#home > .os-container {
		background-color: #fff;
		margin: 0px 24px;
		padding: 16px 0px 8px 0px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	

	#home > .os-container img {
		display: inline-block;
		height: 104px;
		object-fit: contain;
	}

	#home .header-content-container {
		display: flex;
		flex-direction: column;
		height: 320px;
		margin: 11px 24px 32px 24px;
		background-image: url(../../../assets/imgs/mobile/img_banner@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		border-radius: 30px;
		display: none;
		overflow: hidden;
	}

	#home .header-content-container .btn-show-job {
		background: #FFFFFF;
		border-radius: 0px 26px 26px 26px;
		color: #3A87EF;
		padding: 12px 30px;
		position: absolute;
		bottom: 72px;
		left: 48px;
	}

	#home .header-content-container.job {
        background-image: url(../../../assets/imgs/pc/img_zpbeij@2x.png);
    }

	#home .header-content-container.img {
		height: 320px;
        background-image: unset;
        background: linear-gradient(110deg,#E4FDFF 0%,#C2EDF1 100%);
        display: none;
		border-radius: 30px;
		box-sizing: border-box;
    }

	#home .header-content-container.img > img {
		object-fit: contain;
		display: block;
		width: 100%;
		height: 100%;
		object-fit: fill;
	}
	

    #home .header-content-container.show {
        display: flex;
    }

	#home .header-content-container > .header-content > .header-title {
		padding-top: 44px;
		margin-left: 18px;
		font-size: 54px;
		font-weight: 900;
		text-align: left;
		color: #231F20;
		line-height: 66px;
	}

	#home .header-content-container > .header-content > .header-title > img {
		width: 100%;
		object-fit: contain;
		height: 90px;
	}

	#home .header-content-container > .header-content > .header-desc {
		margin-top: 24px;
		font-size: 32px;
		line-height: 32px;
		margin-left: 18px;
		font-weight: 400;
		text-align: left;
		color: #969696;
	}

	#home .header-content-container > .header-content > .header-desc > img {
		object-fit: contain;
		height: 40px;
	}
	/* 菜单栏 start */
	#home > .tab-bar {
		display: flex;
		flex-direction: row;
		height: 130px;
		margin-bottom: auto;
		margin-bottom: 38px;
	}

	#home > .tab-bar > .tab-bar-item {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	#home > .tab-bar > .tab-bar-item > a {
		display: inline-block;
		width: 110px;
		height: 27px;
		padding-top: 103px;
		margin-right: 0px;
		font-weight: 300;
		font-size: 28px;
		color: #231F20;
		white-space: nowrap;
		line-height: 27px;
		position: relative;
	}

	#home > .tab-bar > .tab-bar-item > a::after {
		content: '';
		position: absolute;
		width: 90px;
		height: 90px;
		top: 0;
		left: 10px;
		background-image: url(../../../assets/imgs/mobile/img_zixun@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	#home > .tab-bar > .tab-bar-item:nth-child(2) > a::after {
		background-image: url(../../../assets/imgs/mobile/img_yuyue@2x.png);

	}

	#home > .tab-bar > .tab-bar-item:nth-child(3) > a::after {
		background-image: url(../../../assets/imgs/mobile/icon_searchdoctor@2x.webp);

	}

	#home > .tab-bar > .tab-bar-item:nth-child(4) > a::after {
		background-image: url(../../../assets/imgs/mobile/img_zhongyi@2x.png);

	}
	/* 菜单栏 end */

	#home > .tab-items {
		display: flex;
		margin: 0px 24px;
		flex-direction: row;
		justify-content: space-around;
		background-color: #fff;
		align-items: center;
		border-bottom: 1px solid #ededed;
	}

	#home > .tab-items > .tab-item {
		font-size: 28px;
		color: #636363;
		font-weight: 400;
		margin-right: 0px;
		position: relative;
	}

	#home > .tab-items > .tab-item.current {
		color: #231F20;
		font-size: 36px;
		line-height: 34px;
		position: relative;
		padding-bottom: 28px;
		padding-top: 28px;
		display: inline-block;
		font-weight: bold;
	}

	#home > .tab-items > .tab-item.current::after {
		content: '';
		position: absolute;
		left: 50%;
		bottom: 08px;
		transform: translateX(-50%);
		width: 48px;
		height: 10px;
		background-color: #36C4D0;
		border-radius: 5px;
	}
</style>
